import React, { useEffect, FC } from 'react';
import '../scss/components/_buttons.scss';
export type genericListener = (event?: any) => void;
const useButtonWithKey = (
    callback: genericListener,
    key: string,
    disabled: boolean
) => {
    /** verify if this function is doing what it is expected or is it picking up enter press anywhere on the page */
    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.key === key && !disabled) return callback(event);
        };
        window.addEventListener('keypress', handleKeyPress);
        return () => window.removeEventListener('keypress', handleKeyPress);
    }, [callback, key, disabled]);
};
/**
 *
 * @param {String} color Available values - transparent | light | dark
 */
type buttonType = 'button' | 'reset' | 'submit' | undefined;
interface ButtonProps {
    id?: string;
    onClick: genericListener;
    size?: string;
    color?: string;
    parentClassName?: string;
    className?: string;
    title?: string;
    disabled?: boolean;
    enterToggles?: boolean;
    toggleKey?: string;
    children?: React.ReactElement | string;
    type?: buttonType;
    containerStyle?: object;
    onKeyUp?: any;
    tabIndex?: number;
}
const Button: FC<ButtonProps> = ({
    onClick,
    size = 'lg',
    color = 'transparent',
    parentClassName = '',
    className = '',
    title = '',
    disabled = false,
    enterToggles = false,
    toggleKey = 'Enter',
    children,
    type = 'button',
    containerStyle = {},
    ...props
}) => {
    useButtonWithKey(enterToggles ? onClick : () => {}, toggleKey, disabled);
    return (
        <div
            className={`button-container ${parentClassName}`}
            style={containerStyle}
        >
            <button
                type={type}
                className={`button-${size} button-${color} ${className}`}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {title ? <p className="text-bold">{title}</p> : children}
            </button>
        </div>
    );
};

export default Button;
